import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { from, Observable, throwError, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    passToken: any;
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private toastr: ToastrService, private spinner: NgxSpinnerService) { }
    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        this.spinner.hide();
        if (err.status === 503 || (err.status === 401 && err.error?.detail.includes("Given token not valid for any token type"))) {
            if (err.status === 401 && (err.error?.detail.includes("Given token not valid for any token type") || err.error?.detail.includes("Invalid signature."))) {
                this.toastr.error("Session expired, please signin to continue..")
            }
            localStorage.clear();
            this.router.navigate(['auth/landing'])
            return of(err.message);
        } else if (err.status === 403) {
            this.toastr.error(err.error?.detail || err.error || err.message)
        } else if (err.status == 400) {
            this.toastr.error(err.error.message || err.message)
        } else if (err.status != 404) {
            this.toastr.error(err.message)
        }
        return throwError(err)
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        var backend_url
        let temp_host = window.location.host
        if (temp_host === 'ideapitch.dev.kepler.kairhos.com' || 'localhost') {
            backend_url = window.location.protocol + '//' + temp_host;
            let subdomain = temp_host.split('.');
            subdomain.splice(1, 0, "kepler");
            let testkepler = window.location.protocol + '//' + subdomain.join('.');
        }
        else {
            let subdomain = temp_host.split('.');
            subdomain.splice(1, 0, "kepler");
            backend_url = window.location.protocol + '//' + subdomain.join('.');
        }

        var url = window.location.host.includes('localhost') ? 'https://ideapitch.dev.kepler.kairhos.com' : backend_url;

        if ((req.url.includes('tag') || req.url.includes('maptag') || req.url.includes('recomendedtags') || req.url.includes('removetag')) && !req.url.includes('delivery')) {
            let temp_host = window.location.host
            if (temp_host === 'ideapitch.dev.kepler.kairhos.com' || temp_host.includes('localhost')) {
                backend_url = 'beta.dev.kepler.kairhos.com';
                let subdomain = backend_url.split('.');
                subdomain.splice(1, 0, "tag");
                backend_url = 'https://' + subdomain.join('.');
            }
            else {
                let subdomain = temp_host.split('.');
                subdomain.splice(1, 0, "tag");
                backend_url = 'https://' + subdomain.join('.');
            }

            var url = backend_url;
        }
        
        if (req.url.includes('otp') || req.url.includes('verify-otp')) {
            let path = '';
            path += `${url}/server/v1/${req.url}`;
            req = req.clone({
                url: path
            });  
            if (req.url)
                return next.handle(req);
            else
                return of('error');
        }

        else if ((req.url.includes('tag') || req.url.includes('maptag') || req.url.includes('recomendedtags') || req.url.includes('removetag')) && !req.url.includes('delivery')) {
            let path = '';
            path += `${url}/${req.url}`;
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json'
                },
                url: path
            });
            return next.handle(req);
        }
        else{
            let path = '';
            path += `${url}/server/v1/${req.url}`;
            req = req.clone({
                url: path
            });

            return from(this.getToken()).pipe(
                mergeMap((token: any) => {
                    req = req.clone({
                        setHeaders: {
                            'authorization': `Bearer ${token}`
                        },
                        url: path
                    });
                    return next.handle(req).pipe(catchError(x => this.handleAuthError(x)));
                })
            );
        }
    }

    getToken() {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const token = localStorage.getItem('token');
                if (token) { resolve(token); } else { reject('token not found'); }
            } catch (err) {
                reject('token not found');
            }
        });
    }
}
