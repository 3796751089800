<section class="deletePopup">
    <div class="modal-dialog m-0">
        <div class="modal-content">
            <div class="modal-header">
                <h2 mat-dialog-title class="mb-0 fw-bold">{{staticText?.modal_popup?.confirmation_title}}</h2>
            </div>
            <div class="modal-body">
                <p class="fs-18 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                <p>{{staticText?.modal_popup?.cant_retrieve}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-blue" (click)=closeDialog()>
                            {{staticText?.buttons?.cancel_btn}}</button>
                    </div>
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-white bg-red" (click)=delete()>{{staticText?.buttons?.delete_btn}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>