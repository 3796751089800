import { ElementRef, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as html2canvas from 'html2canvas';
import { MessageService } from './shared/services/message.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AppComponent implements OnInit{
  @ViewChild('screenshotContainer', { static: false }) screenshotContainer: ElementRef;
  
  title = 'kairhos-front-end';
  windowScrolled = false;
  constructor(private router: Router,private spinner:NgxSpinnerService, private renderer: Renderer2,
    private messageService: MessageService, private toastrService: ToastrService) {
    this.router.events.subscribe((e :any) => {
       this.navigationInterceptor(e);
     })
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: any): void {
    if (event instanceof NavigationStart) {
    //  this.spinner.show()
    }
    if (event instanceof NavigationEnd) {
      this.spinner.hide()
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.spinner.hide()
    }
    if (event instanceof NavigationError) {
      this.spinner.hide()
    }
  }
  ngOnInit(): void {
    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset !== 0;
    });
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }
  
  navigateTo(url: any){
    this.router.navigateByUrl(url);
  }
}
