import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  public filterSubject = new BehaviorSubject<{}>({});

  constructor(private http: HttpClient,private transferState: TransferState) { }


  setValue(value: string) {
    this.filterSubject.next(value);
  }

  getValue() {
    return this.filterSubject.asObservable();
  }

  getPortfolios(){
    return this.http.get(`delivery/get-portfolio-list/`);
  }
  
  getFilterList(queryParam: any) {
    return this.http.get(`delivery/get-filtered-list/?${queryParam}`)
  }

  getSubPortfolios(){
    return this.http.get(`/delivery/get-complete-subportfolio-list/`);
  }

  getProgram(){
    return this.http.get(`/delivery/get-complete-program-list/`);
  }

  getOpportunity(){
    return this.http.get(`/delivery/get-complete-opportunity-list/`);
  }

  getProject(){
    return this.http.get(`/delivery/get-complete-project-list/`);
  }

  getVendor(){
    return this.http.get(`/delivery/get-complete-vendor-list/`);
  }

  getTag(){
    return this.http.get(`/delivery/get-complete-tag-list/`);
  }

  getApplicationFilters(){
    return this.http.get(`/delivery/application/filter-options/`);
  }

  getWorkforceFilters(){
    return this.http.get(`/delivery/get-complete-filter-list/`);
  }
  
}
