import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { FormValidatorService } from '../../services/form-validator/form-validator.service';
import { TagsService } from '../../services/tag.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class ModalComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  type: any;
  typeName: any;
  data: any;
  screentype: any;
  fieldsdata: any;
  opportunityForm: FormGroup | any;
  dealForm: FormGroup | any;
  projectMapForm = new FormGroup({});
  options2: any;
  options3: any;

  filteredOptions2: any;
  filteredOptions3: any;

  options1: any;
  filteredOptions1: any;
  subPortOptions: any;
  ownersOptions: any;
  // spocOptions: any;
  // spocfilteredOptions: any;
  nonWhitespaceRegExp = new RegExp('\\S');
  subPortfilteredOptions: any;
  ownersfilteredOptions: any[] = [];
  show = false;
  ownerData: any[] = [];
  submitted = false;
  ifSubmit = false;


  //// project screen model data
  deliveryProjectList: any;
  OpportunityList: any;
  opportunityFilterOptions: Observable<any[]> | any;
  projectFilterOptions: Observable<any[]> | any;


  OpportunityFieldList: any;
  filterOptionType: Observable<any[]> | any;
  filterOptionProgram: Observable<any[]> | any;

  filterOptionPortfolio: Observable<any[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  filterOptionOwners: Observable<any[]> | any;
  // filterOptionSpoc: Observable<any[]> | any;

  @ViewChild('autoInput') input: any;
  @ViewChild('autoInput1') input1: any;
  @ViewChild('autoInput2') input2: any;
  @ViewChild('autoInput3') input3: any;
  projectNumber: any;
  opportunityNumber: any;
  FormFieldOpportunity: any;
  FormFieldProject: any;
  SelectedOpportunity: any;
  selectedproject: any;
  option: any;
  opportunityLabel: any;
  projectLabel: any;
  programdata: any;
  maxChars = 100;
  subPortFolioChild:any[]=[];
  ownerDetails:any={};
  archiveList: any;
  userSearchText: any = "";
  contractSearchText: any = "";
  filterTeamList: any;
  filterContractList: any;
  surveyComments:any;
  tempTags: any = [];
  dealTags:any = [];
  programList: any = [];

  profileForm: FormGroup | any;
  userId: any;
 
  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private _formValidator:FormValidatorService,
    private tagService: TagsService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {
  }

  ngOnInit(): void {
    if (this.type == 'Addopperuntiy') {

      this.createForm();
      this.data = {
        name: true,
        opportunity: false,
        portfolio: false,
        subPortfolio: false,
        program: false,
        owners: false,
        // spoc: false,
      }
      this.getOpportunity();
    }

    if (this.type == 'AddDeal') {

      this.createDealForm();
      this.data = {
        name:true,
        deal_type: true,
        short_description: false,
        program_id: false,
        subPortfolio: false,
        program: true,
        owners: false,
        // spoc: false,
      }
      this.getDealFields();
    }


    if(this.type?.name == "user_owner"){
      this.filterTeamList = this.type.team_list;
    }
    if(this.type?.name == "map_sow_to_project"){
      this.filterContractList = this.type.contract_List;
    }
    if(this.type?.name == "surveyComments"){
      this.surveyComments = this.type?.comments;
    }

    if(this.type?.name == "CreatePlan"){
      // this.getOwner();
    }

    if(this.type === 'EditProfile'){
      this.profileForm = this.fb.group({
        first_name: ['', [Validators.required]],
        last_name: ['', [Validators.required]]
      });

      this.userId = this.dialogData.userId;
      this.profileForm.patchValue(this.dialogData.user);
    }

  }

  get check() {
    return this.projectMapForm.controls;
  }

  showFn(data: any) {
    return data && data.opportunityname ? data.opportunityname : '';
  }

  createForm() {
    this.opportunityForm = this.fb.group({
      name: ['', [Validators.required]],
      opportunity: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      portfolio: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      subPortfolio: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      // program: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      program: [''],
      owners: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]],
      // spoc: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]],
    });
    this.opportunityForm.get("owners").disable();
  }

  createDealForm(){
    this.dealForm = this.fb.group({
      name:['', [Validators.required]],
      deal_type:[''],
      portfolio: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      subPortfolio: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      program: [''],
      program_id: ['',[Validators.required]],
      owners: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]],
      // spoc: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]],
    });
    this.dealForm.get("owners").disable();
  }

  initAutoComplete(): any {
    if (this.data.opportunity) {
      this.filterOptionType = this.opportunityForm.get('opportunity')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.typeFilter(name) : this.filteredOptions2.slice())
        );
    }
    if (this.data.portfolio) {
      this.filterOptionPortfolio = this.opportunityForm.get('portfolio')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.portfolioFilter(name) : this.filteredOptions1.slice())
        );
    }
    if (this.data.program) {
      this.filterOptionProgram = this.opportunityForm.get('program')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.programFilter(name) : this.filteredOptions3.slice())
        );
    }

    // if (this.data.spoc) {
    //   this.filterOptionSpoc = this.opportunityForm.get('spoc')!.valueChanges
    //     .pipe(
    //       startWith(''),
    //       map((value: any) => typeof value === 'string' ? value : value?.name),
    //       map((name: any) => name ? this.spocFilter(name) : this.spocfilteredOptions.slice())
    //     );
    // }
  }

  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions2.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }

  private programFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions3.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private portfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions1.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }

  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }

  // private spocFilter(value: string): any {
  //   const filterValue = value.toLowerCase();
  //   return this.spocfilteredOptions.filter((i: any) => i.name.toLowerCase().includes(filterValue))
  // }


  displayFn(data: any) {
    return data && data.name ? data.name : '';
  }


  validateData(type: any) {
  }

  private Filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.OpportunityFieldList.filter((i: any) => i.opportunity_name.toLowerCase().includes(filterValue));
  }

  editProfile(){
    let payload = this.profileForm.value;
    this.userService.updateProfile(this.userId, payload).subscribe((res: any) => {
      this.spinner.hide();
      this.toastrService.success("Profile update successfully...");
      this.dialogRef.close(this.profileForm.value)
    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error("Error while updating the profile...");
      throw error;
    });
  }

  getOpportunity() {
    this.OpportunityFieldList?.map((i: any) => {
      if (i.field_name == "opportunity_name") {
        this.data.name = i.field_status
        this.data.disName = i.display_name
        if(this.type == 'AddDeal'){
          this.data.disName = 'Deal Name';
        }
        if (!i.field_status) {
          this.opportunityForm.removeControl('name');
        }
      }
      if (i.field_name == "opportunity_type") {
        this.data.opportunity = i.field_status;
        this.options2 = i.field_values;
        this.filteredOptions2 = this.options2;
        this.data.disOpportunity = i.display_name
        if (!i.field_status) {
          this.opportunityForm.removeControl('opportunity');
        }
      }
      if (i.field_name == "program_name") {
        this.data.program = i.field_status;
        this.options3 = i.field_values;
        this.filteredOptions3 = this.options3;
        this.data.disProgram = i.display_name
        if (!i.field_status) {
          this.opportunityForm.removeControl('program');
        }
      }
      if (i.field_name == "opportunity_portfolio") {
        this.data.portfolio = i.field_status;
        this.options1 = i.field_values;
        this.filteredOptions1 = this.options1;
        this.data.disPortfolio = i.display_name
        if (!i.field_status) {
          this.opportunityForm.removeControl('portfolio');
        }
      }
      if (i.field_name == "opportunity_sub_portfolio") {
        this.data.subPortfolio = i.field_status;
        this.subPortOptions = i.field_values;
        this.subPortfilteredOptions = this.subPortOptions;
        this.data.disSubPortfolio = i.display_name
        if (!i.field_status) {
          this.opportunityForm.removeControl('subPortfolio');
        }
      }
      if (i.field_name == "opportunity_owners") {
        this.data.owners = i.field_status;
        this.ownersOptions = i.field_values;
        this.ownersfilteredOptions = this.ownersOptions;
        this.data.disOwners = i.display_name
        if (!i.field_status) {
          this.opportunityForm.removeControl('owners');
        }
      }
      // if (i.field_name == "opportunity_spoc") {
      //   this.data.spoc = i.field_status;
      //   this.spocOptions = i.field_values;
      //   this.spocfilteredOptions = this.spocOptions;
      //   this.data.disSpoc = i.display_name
      //   if (!i.field_status) {
      //     this.opportunityForm.removeControl('spoc');
      //   }
      // }
    })
    this.initAutoComplete();
  }

  getDeal(){

  }

  onSelectionChangePortfolio(event: any) {
    const portFolioId = event.source.value.id;
    this.subPortfilteredOptions = [];
    if (!portFolioId) {
      return;
    }
    this.opportunityForm.patchValue({
      subPortfolio: '',
      owners:''
    })
    this.subPortFolioChild =[];
    Object.keys(this.opportunityForm.controls).forEach((control:any)=>{
      if(control.includes('sub_portfolio_child')){
        this.opportunityForm.removeControl(control);
      }
    })
    
    this.subPortOptions = event.source.value.sub_portfolio;
    this.subPortfilteredOptions = this.subPortOptions;
    this.filterOptionSubPortfolio = this.opportunityForm.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
      );
  }


  onSelectionChangeSubportPortfolio(e: any) {    
    const subportFolioId = e.source.value.id;
    //this.subPortfilteredOptions = [];
    if (!subportFolioId) {
      return;
    }  
    let value = e.source.value;  
   
    this.subPortFolioChild =[];
    if(JSON.stringify(value).includes("sub_portfolio_child")){
      Object.keys(value).forEach((key:any)=>{
        if(key.includes("sub_portfolio_child")&& value[key] && value[key]?.length>0){  
          this.opportunityForm.addControl(key,new FormControl('',[Validators.required,this._formValidator.autocompleteObjectValidator()]));  
          let level = key?.split("_")?.pop();;
          this.subPortFolioChild.push({"key":key,"name":"Sub-Portfolio Child "+level,arrList:value[key]})
        }else{
          this.opportunityForm.patchValue({
            owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
          })
          this.ownerDetails = value.owner_details?.[0];
          this.ownerDetails['mail']=this.ownerDetails['email'];
        }
      });
    }else{
      this.opportunityForm.patchValue({
        owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
      })
      this.ownerDetails = value.owner_details?.[0];
      this.ownerDetails['mail']=this.ownerDetails['email'];
    }
  }
  onSelectionChangeSubportPortfolioChild(e:any){
    let value = e.source.value;
    if(JSON.stringify(value).includes("sub_portfolio_child")){
      Object.keys(value).forEach((key:any)=>{
        if(key.includes("sub_portfolio_child")&& value[key] && value[key]?.length>0){  
          let index = this.subPortFolioChild.findIndex((e:any)=> e.key === key);
          if(index>-1)
            this.subPortFolioChild.splice(index , this.subPortFolioChild.length - index );
          this.opportunityForm.addControl(key,new FormControl('',[Validators.required,this._formValidator.autocompleteObjectValidator()]));       
          let level = key?.split("_")?.pop();;
          this.subPortFolioChild.push({"key":key,"name":"Sub-Portfolio Child "+level,arrList:value[key]})
        }else{            
          this.opportunityForm.patchValue({
            owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
          })          
          this.ownerDetails = value.owner_details?.[0];
          this.ownerDetails['mail']=this.ownerDetails['email'];
        }
      });      
    }else{
      this.opportunityForm.patchValue({
        owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
      })      
      this.ownerDetails = value.owner_details?.[0];
      this.ownerDetails['mail']=this.ownerDetails['email'];
    }
  }
  onSelectionOwners(event: any) {
    if (!!event.first_name == null) {
      event.first_name = '';
    } else if (!!event.last_name == null) {
      event.last_name = '';
    }
  }

  // onSelectionSPoc(event: any) {
  //   if (!!event.first_name == null) {
  //     event.first_name = '';
  //   } else if (!!event.last_name == null) {
  //     event.last_name = '';
  //   }
  // }

  onSelectionProgram(event: any) {
    
  }

  get f() {
    return this.opportunityForm.controls;
  }
  get fd() {
    return this.dealForm.controls;
  }

  cancel() {
    this.dialogRef.close();
  }
  done() {
    if(this.type == 'ArchiveList'){
      this.dialogRef.close(this.archiveList);
    }else if(this.type?.name == 'user_owner'){
      this.dialogRef.close(this.type.team_list);
    }else if(this.type?.name == 'map_sow_to_project'){
      this.dialogRef.close(this.type.contract_List);
    }else{
      this.dialogRef.close(true);
    }
  }

  searchUser(item: any, search: any){
    if(search != ""){
      return item?.name?.toLowerCase().includes(search?.toLowerCase())
    }else{
      return true;
    }
  }

  searchContract(item: any, search: any){
    if(search != ""){
      return item?.contract_name?.toLowerCase().includes(search?.toLowerCase())
    }else{
      return true;
    }
  }

  ngAfterViewInit(){
    if(this.type?.id){
      let el:any = document.getElementById(this.type?.id);
      el.scrollIntoView();
    }  
  }

  getChildTags(data: any){
    this.tempTags = data;
  }

  getChildDealTags(data: any){
    this.dealTags = data;
  }
  
  getDealFields(){
      this.OpportunityFieldList?.map((i: any) => {
        if (i.field_name == "opportunity_name") {
          this.data.name = i.field_status
          this.data.disName = i.display_name
          if(this.type == 'AddDeal'){
            this.data.disName = 'Deal Name';
          }
          if (!i.field_status) {
            this.opportunityForm.removeControl('name');
          }
        }
        if (i.field_name == "opportunity_type") {
          this.data.opportunity = i.field_status;
          this.options2 = i.field_values;
          this.filteredOptions2 = this.options2;
          this.data.disOpportunity = i.display_name
          if (!i.field_status) {
            this.opportunityForm.removeControl('opportunity');
          }
        }
        if (i.field_name == "program_name") {
          this.data.program = i.field_status;
          this.options3 = i.field_values;
          this.filteredOptions3 = this.options3;
          this.data.disProgram = i.display_name
          if (!i.field_status) {
            this.opportunityForm.removeControl('program');
          }
        }
        if (i.field_name == "opportunity_portfolio") {
          this.data.portfolio = i.field_status;
          this.options1 = i.field_values;
          this.filteredOptions1 = this.options1;
          this.data.disPortfolio = i.display_name
          if (!i.field_status) {
            this.opportunityForm.removeControl('portfolio');
          }
        }
        if (i.field_name == "opportunity_sub_portfolio") {
          this.data.subPortfolio = i.field_status;
          this.subPortOptions = i.field_values;
          this.subPortfilteredOptions = this.subPortOptions;
          this.data.disSubPortfolio = i.display_name
          if (!i.field_status) {
            this.opportunityForm.removeControl('subPortfolio');
          }
        }
        if (i.field_name == "opportunity_owners") {
          this.data.owners = i.field_status;
          this.ownersOptions = i.field_values;
          this.ownersfilteredOptions = this.ownersOptions;
          this.data.disOwners = i.display_name
          if (!i.field_status) {
            this.opportunityForm.removeControl('owners');
          }
        }
        // if (i.field_name == "opportunity_spoc") {
        //   this.data.spoc = i.field_status;
        //   this.spocOptions = i.field_values;
        //   this.spocfilteredOptions = this.spocOptions;
        //   this.data.disSpoc = i.display_name
        //   if (!i.field_status) {
        //     this.opportunityForm.removeControl('spoc');
        //   }
        // }
      })
      this.initAutoCompleteDeal();
    }
  
    initAutoCompleteDeal(): any {
      if (this.data.opportunity) {
        this.filterOptionType = this.dealForm.get('name')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.name),
            map((name: any) => name ? this.typeFilter(name) : this.filteredOptions2.slice())
          );
      }
      if (this.data.portfolio) {
        this.filterOptionPortfolio = this.dealForm.get('portfolio')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.name),
            map((name: any) => name ? this.portfolioFilter(name) : this.filteredOptions1.slice())
          );
      }
      if (this.data.program) {
        this.filterOptionProgram = this.dealForm.get('program')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.name),
            map((name: any) => name ? this.programFilter(name) : this.filteredOptions3.slice())
          );
      }
  
      // if (this.data.spoc) {
      //   this.filterOptionSpoc = this.opportunityForm.get('spoc')!.valueChanges
      //     .pipe(
      //       startWith(''),
      //       map((value: any) => typeof value === 'string' ? value : value?.name),
      //       map((name: any) => name ? this.spocFilter(name) : this.spocfilteredOptions.slice())
      //     );
      // }
    }

    onSelectionChangePortfolioDeal(event: any) {
      const portFolioId = event.source.value.id;
      this.subPortfilteredOptions = [];
      if (!portFolioId) {
        return;
      }
      this.dealForm.patchValue({
        subPortfolio: '',
        owners:''
      })
      this.subPortFolioChild =[];
      Object.keys(this.dealForm.controls).forEach((control:any)=>{
        if(control.includes('sub_portfolio_child')){
          this.dealForm.removeControl(control);
        }
      })
      
      this.subPortOptions = event.source.value.sub_portfolio;
      this.subPortfilteredOptions = this.subPortOptions;
      this.filterOptionSubPortfolio = this.dealForm.get('subPortfolio')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
        );
    }
  
  
    onSelectionChangeSubportPortfolioDeal(e: any) {    
      const subportFolioId = e.source.value.id;
      //this.subPortfilteredOptions = [];
      if (!subportFolioId) {
        return;
      }  
      let value = e.source.value;  
     
      this.subPortFolioChild =[];
      if(JSON.stringify(value).includes("sub_portfolio_child")){
        Object.keys(value).forEach((key:any)=>{
          if(key.includes("sub_portfolio_child")&& value[key] && value[key]?.length>0){  
            this.dealForm.addControl(key,new FormControl('',[Validators.required,this._formValidator.autocompleteObjectValidator()]));  
            let level = key?.split("_")?.pop();;
            this.subPortFolioChild.push({"key":key,"name":"Sub-Portfolio Child "+level,arrList:value[key]})
          }else{
            this.dealForm.patchValue({
              owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
            })
            this.ownerDetails = value.owner_details?.[0];
            this.ownerDetails['mail']=this.ownerDetails['email'];
          }
        });
      }else{
        this.dealForm.patchValue({
          owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
        })
        this.ownerDetails = value.owner_details?.[0];
        this.ownerDetails['mail']=this.ownerDetails['email'];
      }
    }
  

}
