<div class="d-flex">
  <div class=" d-flex img-container">
      <img class="img-align" src="./assets/images/bg3.png">
      <div class="centered fw-bold fs-14">Main Menu</div>
  </div>
</div>

<div>
  <div id="setBox">
      <mat-stepper class="mat-nav-list" #stepper orientation="vertical" (selectionChange)="onStepChange($event)">
          <mat-step class="px-1" label="{{menu.name}}" *ngFor="let menu of mainmenu">
              <ng-template matStepLabel>{{menu.name}}</ng-template>
              <ng-template matStepContent>
                  <ul class="nav-list mb-0 nodots">
                    <ng-container  *ngFor="let item of menu.children">
                      <!-- {{route?.url}} -->
                      <ng-container *ngIf="route?.url?.includes('survey-details'); else elseif2">
                        <li (click)="navigateUrl(item.url)" routerLinkActive="active" class="px-1" [class.active]="item?.url?.includes('performanceInsights/hubble')"> {{item.name}}</li>
                      </ng-container>
                      <ng-template #elseif2>
                        <ng-container *ngIf="route?.url?.includes('challenge-workbench'); else elseif3">
                          <li (click)="navigateUrl(item.url)" routerLinkActive="active" class="px-1" [class.active]="item?.url?.includes('challenge-workbench/challenge-statements')"> {{item.name}}</li>
                        </ng-container>
                      </ng-template>
                      <ng-template #elseif3>
                        <ng-container *ngIf="route?.url?.includes('delivery-accelerator'); else elseif1">
                          <li (click)="navigateUrl(item.url)" routerLinkActive="active" class="px-1" [class.active]="item?.url?.includes('delivery-accelerator/dashboard')"> {{item.name}}</li>
                        </ng-container>
                      </ng-template>
                      <ng-template #elseif1>
                        <li (click)="navigateUrl(item.url)" routerLinkActive="active"  class="px-1" [class.active]="route?.url?.includes(item.url)"> {{item.name}}</li>
                      </ng-template>
                    </ng-container>
                  </ul>
              </ng-template>
          </mat-step>
      </mat-stepper>
  </div>
</div>
