import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }
  getuser(url: any) {
    const headerOption = {
      headers: this.setPostLoginHeader()
    }; return this.http.get(`${url}/users/`, headerOption);
  }
  setPostLoginHeader(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'JWT ' + localStorage.getItem('token')
    });
  }

  updateProfile(id: any, payload: any){
    return this.http.patch(`ideapitch/users/${id}/`, payload);
  }
}
