import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: '',
    data: { breadcrumb: null },
    children: [
      {
        path: 'ideapitch', data: { breadcrumb: 'Idea Pitch' },
        loadChildren: () => import('../app/pages/ideapitch/ideapitch.module').then(m => m.IdeaPitchModule)
      },
      {
        path: 'prospect-provider', data: { breadcrumb: 'Provider' },
        loadChildren: () => import('../app/pages/prospect-provider/prospect-provider.module').then(m => m.ProspectProviderModule)
      },
      {
        path: 'prospect-provider-workbench', data: { breadcrumb: 'Provider' },
        loadChildren: () => import('../app/pages/prospect-provider/prospect-provider.module').then(m => m.ProspectProviderModule)
      }
    ],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: '404',
    loadChildren: () => import('../app/pages/pagenotfound/pagenotfound.module').then(m => m.PagenotfoundModule)
  },
  {
    path: '**',
    redirectTo: '404',
  }
];

@NgModule({
  imports: [CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

