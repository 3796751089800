<div class="breadcrumb-bs">
  <ul [ngClass]="{'bcolor':showColorBlue==true}">
    <li>
      <a class="color-history" routerLink="/auth/landing">Home</a>
    </li>
    <li *ngIf="url.includes('actiontracker')">
      <a class="color-history" routerLink="/home">{{staticText?.action_tracker?.tracker}}</a>
    </li>
    <li *ngFor="let breadcrumb of (breadcrumbs$ | async);let i = index; first as isFirst; last as isLast">
      <ng-container *ngIf="isFirst && !isLast">
        <ng-container *ngIf="breadcrumb.url.includes('rfx') || breadcrumb.url.includes('getproposal'); else elseif1"> <a class="color-history"
            routerLink="/program">{{ breadcrumb.label }}</a></ng-container>
        <ng-template #elseif1>
          <ng-container *ngIf="breadcrumb.url.includes('vendor'); else elseif2">
            <a class="color-history" routerLink="/proposals">{{ breadcrumb.label }}</a>
          </ng-container>
        </ng-template>
        <ng-template #elseif2>
          <ng-container *ngIf="breadcrumb.url.includes('toe')||breadcrumb.url.includes('toeUpload'); else elseif3"><a
              class="color-history" routerLink="/deal-structure">{{ breadcrumb.label }}</a></ng-container>
        </ng-template>
        <ng-template #elseif3>
          <ng-container *ngIf="breadcrumb.url.includes('view'); else elseif4"><a class="color-history"
              routerLink="/delivery/projects">{{ breadcrumb.label }}</a></ng-container>
        </ng-template>
        <ng-template #elseif4>
          <ng-container *ngIf="breadcrumb.url.includes('innovation'); else elseif5"><a class="color-history"
              routerLink="/innovation/idea-page">{{ breadcrumb.label }}</a></ng-container>
        </ng-template>
        <ng-template #elseif5>
          <ng-container *ngIf="breadcrumb.url.includes('performanceInsights')||breadcrumb.url.includes('strategicRelevance')||breadcrumb.url.includes('confluence'); else elseif6"><a class="color-history"
              >{{ breadcrumb.label }}</a></ng-container>
        </ng-template>
        <ng-template #elseif6>
          <ng-container *ngIf="breadcrumb.url != '/'; else else1" ><a class="color-history" routerLink="{{breadcrumb.url}}" [queryParams]="breadcrumb.queryParams">{{ breadcrumb.label }}</a>
            </ng-container>
        </ng-template>
        <ng-template #else1>{{ breadcrumb.label }}
        </ng-template>
      </ng-container>

      <ng-container *ngIf="!isFirst && !isLast">
        <ng-container *ngIf="breadcrumb.url.includes('vendor') ||breadcrumb.url.includes('toeUpload')  ; else elseif12">
          <a class="color-history" (click)="goBack()">{{ breadcrumb.label }}</a>
        </ng-container>
        <ng-template #elseif12>
          <ng-container *ngIf="breadcrumb.url.includes('/BVT'); else elseif13">
            <a class="color-history" routerLink="/performanceInsights/hubble">{{ breadcrumb.label }}</a></ng-container>
        </ng-template>
        <ng-template #elseif13>
          <ng-container *ngIf="breadcrumb.url != '/'; else else13">
            <a class="color-history" routerLink="{{breadcrumb.url}}" [queryParams]="breadcrumb.queryParams">{{ breadcrumb.label }}</a>
          </ng-container>
        </ng-template>
        <ng-template #else13>
          <span class="color-history-inactive">{{ breadcrumb.label }}</span>
        </ng-template>
      </ng-container>
  
      <span *ngIf="isLast" class="color-active">{{ breadcrumb.label }}</span>
    </li>
  </ul>
  </div>