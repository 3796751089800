import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HeaderComponent } from './components/header/header.component';
import { ModalComponent } from './components/modal/modal.component';
import { FormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AvatarModule } from 'ngx-avatar';
import { SwiperModule } from "swiper/angular";
import { MaterialmodulesModule } from './materialmodules.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TreefilterPipe } from './pipes/treefilter.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DeletePopupComponent } from './components/delete-popup/delete-popup.component';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component'
import { PipeModule } from './pipes/pipe.module';
import { TransferState } from '@angular/platform-browser';
import { TagComponent } from './components/tag/tag.component';
import { ConfirmationDialogComponent } from './components/tag/confirmation-dialog/confirmation-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { ComingSoonDialog } from './components/coming-soon/coming-soon-dialog';
import { LoadingComponent } from './components/loading/loading.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { FilterCardComponent } from './components/filter-card/filter-card.component';
import { TimeagoModule } from 'ngx-timeago';
import { CommentsComponent } from './components/comments/comments.component';
import { QuillModule } from 'ngx-quill';
import { HighchartsChartModule } from 'highcharts-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChartModule } from 'angular-highcharts';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { MatSliderModule } from '@angular/material/slider';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        ComingSoonDialog,
        ModalComponent,
        FilterCardComponent,
        TreefilterPipe,
        DeletePopupComponent,
        BreadCrumbComponent,
        TagComponent,
        ConfirmationDialogComponent,
        LoadingComponent,
        ConfirmModalComponent, 
        CommentsComponent,
        SideMenuComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxDatatableModule,
        FormsModule,
        NgxPermissionsModule,
        AvatarModule,
        SwiperModule,
        MaterialmodulesModule,
        NgbModule,
        NgCircleProgressModule.forRoot({
        }),
        Ng2SearchPipeModule,
        PipeModule,
        NgbPaginationModule,
        QuillModule,
        ChartModule,
        HighchartsChartModule,
        DragDropModule,
        PowerBIEmbedModule,
        MatSliderModule
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        ComingSoonDialog,
        FilterCardComponent,
        Ng2SearchPipeModule,
        AvatarModule,
        DeletePopupComponent,
        BreadCrumbComponent,
        PipeModule,
        NgbPaginationModule,
        TagComponent,
        LoadingComponent,
        TimeagoModule,
        CommentsComponent,
        SideMenuComponent
    ],
    providers:[TransferState],
    entryComponents: [ ConfirmationDialogComponent ],
})
export class SharedModule { }
