import { T } from '@angular/cdk/keycodes';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { ComingSoonDialog } from '../coming-soon/coming-soon-dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { forEach } from 'lodash';

const TREE_DATA: any = [
    {
      name: 'Idea Pitch',
    }
  ];

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation:ViewEncapsulation.Emulated,
})
export class SideMenuComponent {
  mainmenu: any;
  @ViewChild('stepper') stepper: MatStepper | any;


  constructor(private route: Router, private dialog: MatDialog,    private activeroute: ActivatedRoute,
    ) {
    this.mainmenu = TREE_DATA
  }
  ngOnInit(): void {
    this.activeroute.params.subscribe((res: any) => {
      this.stepSeleted();
 
    })

  }

  stepSeleted(){
    setTimeout(() => {
      for (var i in this.mainmenu) {
        this.mainmenu[i].children.forEach((element:any) => {
          if(this.route.url != '/BVT/survey-details/results'){
            if(this.route.url.includes(element.url)){
              this.stepper.selectedIndex = i; 
            }
          }else{
            this.stepper.selectedIndex = 1; 
          }
  
        });
     }  
    }, 100);

   

  }


  onStepChange(evt:any){
    console.log(evt);
  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  navigateUrl(url: any){
    if(url&&url.indexOf('external')>-1){
    }else if(url&&url.indexOf('ipdb')>-1){
      let temp_host = window.location.host;
      window.open('/server/admin', '_blank');
    }
    else if(url){
      this.route.navigateByUrl(url)
    }else{
      this.dialog.open(ComingSoonDialog);
    }
   
  }
}

