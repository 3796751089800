<div id="wrapper">
    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#D40F78" type="ball-fussion" [fullScreen]="true"></ngx-spinner>
    <router-outlet></router-outlet>
    <div class="to-top" (click)="scrollToTop()" [ngClass]="{ 'show-scrollTop': windowScrolled }">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAOVBMVEVBQkH9//3///88PTw3ODfy9PJiY2Ld390sLSwkJSTAwsBERUQuLi719/VJSklpamkWFxbOz84yMzJyqKThAAACXklEQVR4nO3c604CMRCG4YVVQUU83P/FyuChLMuWXeh0OpP3+SuJvvlICyTYdQAAAAAAAAAAAAAAAAAAAAAAAAAAALb6XW/9J+jq94/vD9Z/hKZ+/7ZeR06UwFXkxGPgKnDib2DcxP/AqIkngTETB4ERE88C4yWOAqMlXgiMlXgxMFLiRGCcxL6bCJTEpwCJ/evjVGCMxL7LBEZIzC4YIfHKgv4Try7oPXFWoOfEGU9R34kzF/SbOHtBr4kLFvSZuGhBj4mZBWO8Rs0suJ7+iaPE3IKbjxf/idnAXffsPjH3FN1sDw/wnnhlQeE78eqCwnNidsHt/8P8Js5aUHhNnLmg8JmYXXB39mCPiQsWFP4SZ1wTQ94SZx8yia/ExQsKT4k3LCj8JC48ZBIviTcuKHwk3ryg8JC46KIfaz/xrgVF64k3XRNDbSfeccgkLScWWFC0m1hkQdFq4t2HTNJmYrEFRYuJBRcU7SXeedGPtZZYeEHRVmKha2KopcSih0zSTqLKgqKVRKUFRRuJCodM0kKi4oLCPlF1QWGdWPyiH7NNVF9QWCaqXRNDdonKh0xilrivsqDIJup90Xa3qbOgyCSuvgr/rhNTG5YPnE5U3XDqoNEInEpUP2ouJeoEXk40uS5KHzLJONHkytdaUJwnVnrZNkzUW1AME01eemsuKE4TK759Som6C4qUaPIWWHtB8ZdY+WOMn8QagX+JJh9F1Qn8STT4OPGQWCnwmGjxkfD+U/EF8Jnti8k3husFHn5X8P9MBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMCdb1F1HmHm3lXzAAAAAElFTkSuQmCC" alt="to-top-icon" width="35" />
    </div>
</div>
<div id="warning-message">
    Site is only viewable in landscape mode
</div>

