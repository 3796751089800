import { Component, EventEmitter, HostListener, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { map, startWith, take } from 'rxjs/operators';
import { ViewEncapsulation } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.Emulated
})
export class HeaderComponent implements OnInit {

    authenticated: boolean = false;
    showProfilePopUp: boolean = false;
    user: any;
  
    constructor(
    private msalAuthService: MsalService,
    private dialog: MatDialog,private renderer: Renderer2,
    private router: Router, private datepipe: DatePipe, public fb: FormBuilder, private activatedRoute: ActivatedRoute, 
    private spinner: NgxSpinnerService, private toastrService: ToastrService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.activatedRoute.params.subscribe((res: any) => {
   
    })
  }

  ngOnInit() {
    if(localStorage.getItem('token')){
      this.user = JSON.parse(localStorage.getItem('user') || '{}');
      this.authenticated = true;
    }
  }

  logOut() {
    this.showProfilePopUp = false;
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
    dialog.componentInstance.type = 'Logout';
    dialog.afterClosed().subscribe(data => {
      if (!!data) {
        localStorage.clear();
        window.location.reload();
        
      }
    })
  }

  editProfile(){
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true, data: { userId: this.user.id, user: this.user } });
    dialog.componentInstance.type = 'EditProfile';
    dialog.afterClosed().subscribe(data => {
      if (typeof data == 'object') {
        this.user.first_name = data.first_name
        this.user.last_name = data.last_name
        localStorage.setItem('user', JSON.stringify(this.user))
        this.user = JSON.parse(localStorage.getItem('user') || '{}');
      }
    })
  }

  navigateTo(url: any){
    this.router.navigateByUrl(url)
  }
}
