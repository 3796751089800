<h6 class="mt-3">Feedback / Comments</h6>
<ng-container *ngFor="let comment of comments">
    <div style="padding: 5px 10px; border: 0.5px solid #b5acac;    border-top-left-radius: 15px;" class="mb-2">
        <div class="d-flex justify-content-between">
            <div>
               <div *ngIf="comment.text" [innerHTML]="comment?.text"></div> 
                <div class="d-flex align-items-center cursor-pointer" *ngIf="comment?.file_name">
                    <div class="d-flex">
                        <div class="mr-2 mt-1">
                            <img *ngIf="getFileType(comment?.file_name) == 'doc' || getFileType(comment?.file_name) == 'docx'"
                                src="./assets/images/word.svg" height="30">
                            <img *ngIf="getFileType(comment?.file_name) == 'pdf'" src="./assets/images/pdf.svg"
                                height="40">
                            <img *ngIf="getFileType(comment?.file_name) == 'xls' || getFileType(comment?.file_name) == 'xlsx'"
                                src="./assets/images/excel.svg" height="30">
                            <img *ngIf="getFileType(comment?.file_name) == 'csv'" src="./assets/images/csv.png"
                                height="40">
                            <mat-icon
                                *ngIf="getFileType(comment?.file_name) !== 'pdf' && getFileType(comment?.file_name) !== 'doc' && getFileType(comment?.file_name) !== 'docx' && getFileType(comment?.file_name) !== 'xls' && getFileType(comment?.file_name) !== 'xlsx' && getFileType(comment?.file_name) !== 'csv'"
                                mat-list-icon class="material-icons my-auto fs-1 text-danger">
                                description</mat-icon>                                    
                        </div>
                        <div class="m-2 mt-3 ms-0 text-primary fs-12">
                            {{comment?.file_name}}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <span style="font-size: 10px"> You on <span style="color: rgb(92, 92, 184); font-weight: 600"> July
                        24, 2024 12:15:12 PM </span> 
                </span>
            </div>
        </div>
    </div>
</ng-container>
<p class="my-4"></p>
<quill-editor [modules]="modules" [(ngModel)]="content"></quill-editor>
<div class="mt-2">
    <button mat-button class="mr-1" color="addbtn" (click)="addComment()"><mat-icon>add</mat-icon>Add Comment</button>
    <button mat-button color="addattc" (click)="fileInput.click()"><mat-icon>attachment</mat-icon>Add Attachment</button>
    <span *ngIf="attachementName" class="text-primary" >{{attachementName}}</span>
    <input hidden #fileInput type="file" id="file" (change)="onFileSelected($event)">
</div>